import draw2d from '../packages'

/**
 * @class
 * Static values for point orientation.
 * 
 */
draw2d.geo.PositionConstants = {
  /**
   *
   */
  NORTH:  1,
  SOUTH:  4,
  WEST:  8,
  EAST: 16
}

