import draw2d from '../../packages'


/**
 * @class

 *
 * @example
 *
 *    let icon =  new draw2d.shape.icon.GRaphael();
 *
 *    canvas.add(icon,50,10);
 *
 * @inheritable
 * @author Andreas Herz
 * @extends draw2d.shape.icon.Icon
 */
draw2d.shape.icon.GRaphael = draw2d.shape.icon.Icon.extend(
  /** @lends draw2d.shape.icon.GRaphael.prototype */
  {

  NAME: "draw2d.shape.icon.GRaphael",

  /**
   *
   * Creates a new icon element which are not assigned to any canvas.
   * 
   * @param {Object} attr the configuration of the shape
   */
  init: function (attr, setter, getter) {
    this._super(extend({width: 50, height: 50}, attr), setter, getter)
  },

  /**
   * @private
   * @returns {Object} the raphaelJS path object
   */
  createSet: function () {
    return this.canvas.paper.path("M28.833,15.709c0-1.477-0.574-2.862-1.617-3.904l-7.002-7.001l-0.003,0.002c-1.027-1.03-2.445-1.62-3.9-1.62c-1.455,0-2.871,0.59-3.9,1.621l-0.002-0.002l-7,7C4.376,12.836,3.79,14.25,3.79,15.709s0.586,2.873,1.619,3.902l6.312,6.312c0.253,0.285,0.519,0.556,0.8,0.8c1.049,0.989,2.463,1.534,3.908,1.51c1.417-0.021,2.783-0.604,3.785-1.604l6.812-6.812c0.021-0.021,0.035-0.029,0.062-0.062l0.143-0.146c0.271-0.271,0.484-0.562,0.725-0.86l-0.012-0.002C28.516,17.85,28.833,16.805,28.833,15.709zM18.77,25.17c-1.121,1.119-2.917,1.336-4.271,0.514l0.002-0.002c-0.213-0.145-0.414-0.303-0.61-0.471c-0.016-0.016-7.04-7.041-7.04-7.041c-1.34-1.34-1.342-3.584,0-4.92l7-6.998c1.121-1.121,2.908-1.338,4.259-0.512v0.002c0.213,0.141,0.414,0.299,0.604,0.467c0.021,0.016,7.053,7.043,7.053,7.043c0.396,0.388,0.655,0.852,0.818,1.348l-2.607,0.006c-0.537-3.754-3.769-6.641-7.667-6.641c-4.277,0-7.744,3.468-7.745,7.746c0.001,4.277,3.468,7.744,7.745,7.744c3.917,0,7.156-2.91,7.668-6.688l2.638-0.021c-0.16,0.521-0.441,1.02-0.849,1.412L18.77,25.17zM16.312,16.789c0.002,0,0.002,0,0.004,0l5.476-0.02c-0.5,2.562-2.76,4.518-5.48,4.521c-3.084-0.004-5.578-2.5-5.584-5.582c0.006-3.084,2.5-5.58,5.584-5.584c2.708,0.004,4.959,1.929,5.472,4.484l-5.476,0.018c-0.596,0.002-1.078,0.488-1.076,1.084C15.233,16.308,15.715,16.789,16.312,16.789z")
  }
})

